export const auth = {
  adminLogin: {
    url: "login",
    method: "POST",
    data: null,
  },
  vendorLogin: {
    url: "vendor/login",
    method: "POST",
    data: null,
  },
  changePassword: {
    url: "vendor/resetPassword",
    method: "POST",
    data: null,
  },
};
